<script setup>
const props = defineProps({
    pageData: Object,
    links: Array,
});
const emit = defineEmits(['setPage']);

const getPage = (url) => {
    if (url) {
        const params = new URLSearchParams(url.split('?')[1]);

        return params.get('page');
    }
};
</script>
<template>
    <div class="flex flex-wrap items-center justify-center">
        <div class="flex flex-wrap gap-2" v-if="links.length > 3">
            <template v-for="(link, key) in links">
                <div
                    v-if="link.url === null && key !== 0 && key !== links.length - 1"
                    :key="key"
                    class="px-4 py-3 mb-1 mr-1 text-base leading-4 text-white"
                    v-html="link.label"
                />

                <button
                    v-else-if="key === 0"
                    :key="`first-${key}`"
                    class="flex items-center justify-center w-[40px] h-[40px] text-base leading-4 transition-colors rounded-tl rounded-bl tb text-white hover:bg-orange rounded"
                    :class="{
                        'bg-orange ': link.active,
                        'cursor-default hover:bg-blue': link.url === null,
                    }"
                    :href="link.url"
                    preserve-state
                    preserve-scroll
                    @click="link.url ? emit('setPage', getPage(link.url) || 1) : null"
                >
                    <img
                        class="w-[10px] h-auto rotate-180"
                        src="@/../img/icon/pagination-arrow-white.svg"
                        :class="{
                            'opacity-30': link.url === null,
                        }"
                        :alt="$t('Previous page')"
                    />
                </button>

                <button
                    v-else-if="key === links.length - 1"
                    :key="`last-${key}`"
                    class="flex items-center w-[40px] h-[40px] justify-center rounded-tr rounded-br px-1 mb-1 mr-1 text-base leading-4 transition-colors text-white hover:bg-blue/10"
                    :href="link.url"
                    preserve-state
                    preserve-scroll
                    :class="{
                        'cursor-default hover:bg-blue': link.url === null,
                    }"
                    @click="link.url ? emit('setPage', getPage(link.url) || 1) : null"
                >
                    <img
                        class="w-[10px] h-auto"
                        src="@/../img/icon/pagination-arrow-white.svg"
                        :alt="$t('Next page')"
                        :class="{
                            'opacity-30': link.url === null,
                        }"
                    />
                </button>

                <button
                    v-else
                    :key="`link-${key}`"
                    class="flex items-center w-[40px] h-[40px] justify-center px-1 text-base leading-4 transition-colors text-white hover:bg-orange duration-300 hover:text-white rounded"
                    :class="{
                        'bg-orange ': link.active,
                    }"
                    :href="link.url"
                    v-html="link.label"
                    preserve-state
                    preserve-scroll
                    @click="link.url ? emit('setPage', getPage(link.url) || 1) : null"
                ></button>
            </template>
        </div>
    </div>
</template>
